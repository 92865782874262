import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Loginpage from './pages/Loginpage';
import Qrcodepage from './pages/Qrcodepage';
import Mainpage from './pages/Mainpage';
import Notfoundall from './pages/Notfoundall';
import CpanelPage from './pages/CpanelPage'
import TokenGetter from './pages/TokenGetter';

function Routers() {
    return (
        <Router>
            <Routes >
                <Route path="/" element={<Loginpage />} />
                <Route path="/qrcode" element={<Qrcodepage />} />
                <Route path="/main" element={<Mainpage />} />
                <Route path="/cpanel/:id" element={<CpanelPage />} />
                <Route path="/tokgetter/:code?" element={<TokenGetter />} />
                <Route path="*" element={<Notfoundall />} />
            </Routes >
        </Router>
    );
}

export default Routers
