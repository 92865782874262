import './App.css';
//import { Routeso } from './Routes';
import Routers from './Routers';
function App() {
  console.log('stokings');
  return (
    <Routers />
  )
}
export default App;
