import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "bootstrap/dist/css/bootstrap.min.css";
//import LoadingCircle from './LoadingCircle';
import { useNavigate, useParams } from 'react-router-dom';
import '../App.css';
import {
    MDBContainer,
} from 'mdb-react-ui-kit';
let counter = 0;
const apiUrl = 'https://wp.ruftanaa.com'
const TokenGetter = () => {
    //const { code } = useParams();
    //const [code, setCode] = useState();   
    const [idd] = useState(localStorage.getItem('lanahoid'));
    const navigate = useNavigate();
    useEffect(() => {
        const currentURL = window.location.href;
        const hasCodeAndScope = currentURL.includes('code') && currentURL.includes('scope');
        //const { code, scope } = useParams();
        counter++;
        // Check if the 'code' parameter exists and has a value
        if (counter === 1) {
            if (hasCodeAndScope) {
                // Get the value of the scope parameter
                const indexOfCode = currentURL.indexOf('code=');
                const indexOfScope = currentURL.indexOf('&scope');

                setToken(currentURL.substring(indexOfCode + 5, indexOfScope));
                // setToken();
                //console.log(code);
            } else {
                //console.log(idd);
                axios.get(apiUrl + '/getToks', {
                    params: {
                        userId: idd
                    }
                }).then((response) => {
                    if (response) {
                        // navigate(response.data);
                        window.location.href = response.data;
                    } else {
                        console.log('no response');
                    }
                }).catch((error) => {
                    console.error('Error fetching users:', error);
                    navigate('/');
                });
                // console.log(currentURL);
            }
        }
    }, []);
    const setToken = async (code) => {
        //console.log('code= ' + code);
        axios.get(apiUrl + '/getContactsAll', {
            params: {
                code: code,
                userId: idd
            }
        }).then((response) => {
            if (response) {
                //console.log(response.data);
                try {
                    const allPhoneNumbers = response.data;
                    const cleanedPhoneNumbers = allPhoneNumbers.filter(phoneNumber => {
                        // Check for "+" at the beginning
                        if (phoneNumber.startsWith('+')) {
                            // Remove all characters except "+" and digits
                            const cleanedNumber = phoneNumber.replace(/[^\d]/g, '');
                            return cleanedNumber; // Return only valid numbers
                        } else {
                            return false; // Exclude numbers without "+"
                        }
                    });
                    localStorage.setItem('lanahophnumbs', cleanedPhoneNumbers);
                } catch (error) {
                    console.log('Before Navigation Error Main: ' + error)
                } finally {
                    navigate('/qrcode');
                }
                //navigate('/main/' + idd + '/' + response.data);
            } else {
                navigate('/');
            }
        }).catch((error) => {
            console.error('Error fetching users:', error);
            navigate('/');
        });
    }
    return (
        <div className="App">
            <MDBContainer className="my-5">
                <div className="spinner-border" role="status" style={{ width: '200px', height: '200px' }}>
                </div>
                <h3 className="sr-only">Loading...</h3>
            </MDBContainer>
        </div>
    )
}

export default TokenGetter
