import React, { useState, useEffect } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import '../App.css';
const apiUrl = 'https://wp.ruftanaa.com';

function CpanelPage() {
    const [users, setUsers] = useState({});
    const [formData, setFormData] = useState({
        id: 0,
        name: '',
        phone: '',
        pin: '',
        role: '2', // Default role value
        apiKey: '',
        apiSecret: ''
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Use formData in any way you want, for example:
        // console.log('Form Data:', formData);
        try {
            // Send data to Node.js Express server
            const response = await axios.post(apiUrl + '/submituser', {
                formData
            });

            // Handle the response accordingly
            if (response.data.success) {
                fetchData();
                handleReset();
            } else {
                alert('Error: User exists or there is other error in your field.');
            }
        } catch (error) {
            // Handle error from server
            console.error('Error:', error);
        }
    };
    const handleReset = () => {
        setFormData({
            id: 0,
            name: '',
            phone: '',
            pin: '',
            role: '2', // Default role value
            apiKey: '',
            apiSecret: ''
        });
    };
    const populateFormData = (user) => {
        setFormData({
            id: user.id || 0,
            name: user.name || '',
            phone: user.phoneNumber || '',
            pin: user.pinCode || '',
            role: user.role || 2,
            apiKey: user.apiKey || '',
            apiSecret: user.apiSecret || ''
        });
    };
    useEffect(() => {
        document.title = 'WhatsApp Help';


        // Call the fetchData function
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            // Fetch user data (replace this with your actual data fetching logic)
            const response = await fetch(apiUrl + '/users');
            const data = await response.json();
            //   console.log(data);
            // Set the fetched users into the state
            setUsers(data.slice().sort((a, b) => b.id - a.id));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    return (
        <div>
            <div className='container'>
                <div className='row' style={{ marginBottom: '20px' }}>
                    <div className='col-12'>
                        <div className='card'>
                            <div className='card-header'>
                                <h3 style={{ textAlign: 'center' }}>Users Form</h3>
                            </div>
                            <div className='card-body'>
                                <form onSubmit={handleSubmit}>
                                    <div className='row'>

                                        {/* Input fields */}
                                        <div className='col-6'>
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">Name *</span>
                                                </div>
                                                <input type="text" className="form-control" aria-label="Username" aria-describedby="basic-addon1" name="name" value={formData.name} onChange={handleChange} />
                                                <input type="text" className="form-control" aria-label="Username" aria-describedby="basic-addon1" name="id" value={formData.id} onChange={handleChange} hidden />
                                            </div>
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">Phone *</span>
                                                </div>
                                                <input type="text" className="form-control" placeholder='Used as Username' aria-label="Username" aria-describedby="basic-addon1" name="phone" value={formData.phone} onChange={handleChange} />
                                            </div>
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">PIN *</span>
                                                </div>
                                                <input type="text" className="form-control" placeholder='More Than 4 Digits' aria-label="Username" aria-describedby="basic-addon1" name="pin" value={formData.pin} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">Role</span>
                                                </div>
                                                <select className="form-select" name="role" value={formData.role} onChange={handleChange}>
                                                    <option value="2">Team</option>
                                                    <option value="1">Admin</option>
                                                </select>
                                            </div>
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">APIK</span>
                                                </div>
                                                <input type="text" className="form-control" placeholder='API KEy' aria-label="Username" aria-describedby="basic-addon1" name="apiKey" value={formData.apiKey} onChange={handleChange} />
                                            </div>
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">APIS</span>
                                                </div>
                                                <input type="text" className="form-control" placeholder='API Secret' aria-label="Username" aria-describedby="basic-addon1" name="apiSecret" value={formData.apiSecret} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className='col-12 d-flex justify-content-center align-items-center' style={{ gap: '20%' }}>
                                            <button type='submit' className='btn btn-success btn-sm'>Submit</button>
                                            <button type='button' onClick={handleReset} className='btn btn-danger btn-sm float-right'>Cancel</button>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='card card-secondary'>
                            <div className='card-header'>
                                <h4 style={{ textAlign: 'center' }}>Users</h4>
                            </div>
                            <div className='card-body'>
                                <div className='table-responsive'>
                                    <table className="table table-stripped">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Phone</th>
                                                <th scope="col">Role</th>
                                                <th scope="col">ApiKey</th>
                                                <th scope="col">ApiSec</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users && users.length > 0 && users.map((user) => (
                                                <tr key={user.id}>
                                                    <td>{user.id}</td>
                                                    <td>{user.name}</td>
                                                    <td>{user.phoneNumber}</td>
                                                    <td>{user.role === '1' ? 'Admin' : user.role === '2' ? 'Team' : 'Other Role'}</td>
                                                    <td>{user.apiKey}</td>
                                                    <td>{user.apiSecret}</td>
                                                    <td><button className='btn btn-success btn-sm py-0' onClick={() => populateFormData(user)}>Edit</button></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CpanelPage
