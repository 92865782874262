import { React, useState, useEffect } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
//import "bootstrap/dist/js/bootstrap.bundle.min";
//import { Link } from 'react-router-dom';
import './../App.css';
import {
    MDBBtn,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBInput
} from 'mdb-react-ui-kit';
const apiUrl = 'https://wp.ruftanaa.com' //process.env.REACT_APP_API_URL;//'http://wp.ruftanaa.com:3001'
function Loginpage() {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [pinCode, setPinCode] = useState("");
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);

    useEffect(() => {
        document.title = 'WhatsApp Help';
        axios.get(apiUrl + '/users') // Update URL to match your server address
            .then((response) => {
                setUsers(response.data);
            })
            .catch((error) => {
                console.error('Error fetching users:', error);
            });
    }, []);
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Send data to Node.js Express server
            const response = await axios.post(apiUrl + '/submit', {
                phoneNumber,
                pinCode,
            });

            // Handle the response accordingly
            if (response.data.success) {
                if (response.data.role === '2') {
                    try {
                        localStorage.setItem('lanahoid', phoneNumber);
                    } catch (error) {
                        console.log('Before Navigation Error: ' + error)
                    } finally {
                        navigate('/tokgetter');
                    }
                } else if (response.data.role === '1') {
                    navigate('/cpanel/' + response.data.id);
                } else {
                    alert('Something Is Wrong Error Code 79986');
                }
            } else {
                // Handle incorrect data or error
                // Example: Show an error message to the user
                alert('Invalid phone number or PIN');
            }
        } catch (error) {
            // Handle error from server
            console.error('Error:', error);
        }
    };
    const handleChange = (event) => {
        setPhoneNumber(event.target.value); // Update the selected value in state
    };
    return (
        <MDBContainer className="my-5">

            <MDBCard style={{ backgroundColor: 'wheat' }}>
                <MDBRow className='g-0'>

                    <MDBCol md='4'>

                    </MDBCol>

                    <MDBCol md='4'>
                        <MDBCardBody className='d-flex flex-column'>

                            <div className='d-flex flex-row mt-2 justify-content-center'>
                                <MDBIcon fas icon="cubes fa-3x me-3" style={{ color: '#ff6219' }} />
                                <span className="h1 fw-bold mb-0">Login</span>
                            </div>

                            <h5 className="d-flex flex-row mt-2 justify-content-center" style={{ letterSpacing: '1px' }}>Please provide the credentials</h5>
                            <hr></hr>
                            <form onSubmit={handleSubmit}>
                                TEAM
                                <select className="form-select" aria-label="Default select example" value={phoneNumber} onChange={handleChange}>
                                    <option>Select Team</option>
                                    {users.map((user, index) => (
                                        <option
                                            value={user.phoneNumber} key={index}>
                                            {user.name}
                                        </option>
                                    ))}
                                </select>
                                <br></br>
                                PIN
                                <MDBInput wrapperClass='mb-4' id='formControlLg' type='text' size="md"
                                    value={pinCode}
                                    onChange={(e) => setPinCode(e.target.value)} />
                                <MDBBtn className="mb-4 px-5" color='dark' size='lg' type='submit'>Login</MDBBtn>
                            </form>
                            <div className='d-flex flex-row justify-content-start'>
                                <a href="#!" className="small text-muted me-1">Powered By.</a>
                                <a href="#!" className="small text-muted">Isackson Technologies</a>
                            </div>
                        </MDBCardBody>
                    </MDBCol>
                    <MDBCol md='4'>
                    </MDBCol>
                </MDBRow>
            </MDBCard>

        </MDBContainer>
    )
}

export default Loginpage