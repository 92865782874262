import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
function Notfoundall() {
    return (
        <div>
            Not found
        </div>
    )
}

export default Notfoundall