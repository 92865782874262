import React, { useState, useEffect } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import socketService from '../socketService';
import { useNavigate } from 'react-router-dom';
import {
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBRow,
    MDBCol,
    MDBCardHeader,
    MDBTextArea,
    MDBInput,
    MDBFile
}
    from 'mdb-react-ui-kit';
import '../App.css';
const apiUrl = 'https://wp.ruftanaa.com'
let counter = 0;
let resultHolder = "";
let conso = "";
//let counternet = 0;
const socket = socketService.getSocket();
function Mainpage() {
    const [phoneNumbersList] = useState(localStorage.getItem('lanahophnumbs').split(","));
    const [id] = useState(localStorage.getItem('lanahoid'));
    const navigate = useNavigate();
    const [resultLabel, setResultLabel] = useState("Result View");
    const [fromInterval, setFromInterval] = useState("1");
    const [toInterval, setToInterval] = useState("3");
    const [stopAfter, setStopAfter] = useState("0");
    const [mainMessage, setMainMessage] = useState("");
    const [selectedImage, setSelectedImage] = React.useState(null);
    const [messageStatus, setMessageStatus] = useState("none");
    const [startFrom, setStartFrom] = useState(0);
    const [counternet, setCounternet] = useState(0);
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedImage(URL.createObjectURL(file));
        }
    };
    const initializeMessaging = async () => {
        try {
            if (+startFrom > 0 && !isNaN(startFrom)) {
                setCounternet((+startFrom - 1));
            }
        } catch (error) {
            console.log('Setting Starter :' + error);
        } finally {
            setMessageStatus('active');
            setStartFrom(0);
            console.log('start clicked');
        }
    }

    const pauseMessaging = async () => {
        if (id === null || id === "") {
            alert('ID is not set.');
            navigate('/');
            return;
        }
        console.log('pause clicked');
        try {
            setMessageStatus("paused");
            socket.emit("arrivedMain", {
                id
            });
        } catch (error) {
            console.error('Pause Error:', error);
            alert('Error 2');
            navigate('/');
        }
    };
    const stoppermachine = async () => {
        if (id === null || id === "") {
            alert('ID is not set.');
            navigate('/');
            return;
        }
        try {
            setMessageStatus("stopped");
            setCounternet(0);
        } catch (error) {
            console.error('Pause Error:', error);
            alert('Error 3');
            navigate('/');
        }
    };
    useEffect(() => {
        //    const phonesetter = async () => {
        //        setPhoneNumbers(phoneNumbersList);
        //    };
        //phonesetter();
        counter++;
        if (counter === 1) {
            socket.emit("arrivedMain", {
                id
            });

            //setPhoneNumbersList(phoneNumbersList2);
        }
        socket.on("mainRowFound", async (data) => {
            setCounternet(data.rows);
            setStartFrom(data.rows);
            setResultLabel('Start At: ' + data.rows);
        })
        socket.on("Finishhed", async (data) => {
            resultHolder = resultHolder + "\n Finished";
            setResultLabel(resultHolder);
            resultHolder = "";
            conso = "";
        });
        socket.on("Stoppped", async (data) => {
            resultHolder = resultHolder + "\n Stopped At => " + data.itemLocation;
            setResultLabel(resultHolder);
            resultHolder = "";
            conso = "";
        });
        socket.on("Paussed", async (data) => {
            resultHolder = resultHolder + "\n Paused At => " + data.itemLocation;
            setResultLabel(resultHolder);
            resultHolder = "";
            conso = "";
        });
        socket.on("sentMessageReply", async (data) => {
            //counternet++;
            if (data.phoneNumbers !== conso) { // SO that it will display only once
                resultHolder = resultHolder + data.phoneNumbers + ' => ' + data.status + " | " + data.resMessage + "\n";
                setResultLabel(resultHolder);
                //setCountMessage(data.itemLocation);
                conso = data.phoneNumbers;
            }
        });
        socket.on("disconclient", (data) => {
            console.log('Client Disconnected Reason: ' + data.reason);
            alert('Error 4: ' + data.reason);
            navigate('/');
        });
        socket.on("notReady", (data) => {
            alert('Whatsapp Needs to Reverify PLease.');
            navigate('/qrcode/' + id);
        });
        socket.on('stopMessageResponce', (data) => {
            resultHolder = resultHolder + "\n" + data.resmessage;
            setResultLabel(resultHolder);
            resultHolder = "";
        });
        socket.on('newMessageWPResponse', (data) => {
            //alert('Message Not Sent Because WhatsApp is Disconnected. This happens mainly if you have refreshed the page.');
            alert('Error 5');
            navigate('/');
        });
    });
    useEffect(() => {
        let isMounted = true; // Flag to check if component is still mounted
        let stopMessaging = false; // Control variable to stop recursion
        let looper = counternet;
        try {
            // console.log('epif : ' + counternet);
            //const newArray = phoneNumbersList.slice(looper);
            if (id === null || id === "") {
                alert('ID is not set.');
                navigate('/');
                return;
            }
            const delay = (seconds) => {
                return new Promise((resolve) => {
                    setTimeout(resolve, seconds * 1000);
                    console.log("Secs" + (seconds));
                });
            };
            if (phoneNumbersList.length > 0) {
                const startMessaging = async () => {
                    if (!isMounted || stopMessaging) return;
                    const i = looper;
                    const indexpoint = i + 1;
                    if (messageStatus === 'paused') {
                        resultHolder = resultHolder + "\n Paused ON => " + indexpoint;
                        setResultLabel(resultHolder);
                        resultHolder = "";
                        conso = "";
                        setMessageStatus('none');
                        //counternet++;
                    } else if (messageStatus === 'stopped') {
                        socket.emit('stopMessageWP', { id: id, indexPoint: indexpoint });
                        conso = "";
                        setMessageStatus('none');
                    } else if (messageStatus === 'active') {
                        if (stopAfter === (indexpoint + '')) {
                            try {
                                resultHolder = resultHolder + "\n Paused ON => " + indexpoint;
                                setResultLabel(resultHolder);
                                resultHolder = "";
                                conso = "";
                                setMessageStatus('none');
                            } catch (error) {
                                console.error('Pause Error:', error);
                                alert('Error 6');
                                navigate('/');
                            } finally { return }
                        }
                        try {
                            const phNumber = phoneNumbersList[i].replace(/\+/g, '');
                            console.log(i);
                            if (phNumber.startsWith("46") || phNumber.startsWith("47") || phNumber.startsWith("45")) {
                                socket.emit('newMessageWP', {
                                    phoneNumber: phNumber,
                                    message: mainMessage,
                                    image: selectedImage,
                                    indexPoint: indexpoint,
                                    id: id
                                })
                            } else {
                                resultHolder = resultHolder + "\n" + 'Wrong Number: ' + phNumber + ' | ';
                                setResultLabel(resultHolder);
                            }
                            //const counternet2 = counternet + 1;
                            looper++;
                            setCounternet(looper);
                            //setCounternet(counternet2);
                        } catch (error) {
                            console.error('Send Error:', error);
                            alert('Error 1');
                            navigate('/');
                        } finally {
                            await delay(Math.floor(Math.random() * (parseInt(toInterval, 10) - parseInt(fromInterval, 10) + 1)) + parseInt(fromInterval, 10));
                            //console.log('lato 1: ' + looper)
                            //console.log('lato 2 : ' + phoneNumbersList.length)
                            if (phoneNumbersList.length > looper) {
                                //console.log('lato 3: ' + looper)
                                startMessaging();
                            } else {
                                resultHolder = 'Finished';
                                socket.emit('finishedExec', { id: id });
                            }
                        }
                    }
                }
                if (messageStatus !== 'none') {
                    startMessaging();
                }
            }
        } catch (error) {
            console.log(error);
        }

        return () => {
            isMounted = false; // Set the flag to indicate unmounting
            stopMessaging = true; // Set the flag to stop the recursive calls
        };
    }, [messageStatus]);
    return (
        <div>
            <MDBContainer className="my-5">
                <MDBCard>
                    <MDBRow className='g-0'>
                        <MDBCardHeader>
                            <h2 style={{ textAlign: 'center' }}>Message Center</h2>
                            <h5 style={{ textAlign: 'center' }}>{phoneNumbersList.length} Contacts Ready For Processing From {counternet}</h5>
                        </MDBCardHeader>
                        <MDBCol md='4'>
                            <MDBCardBody className='d-flex flex-column'>
                                <MDBRow className='g-0'>
                                    <MDBCol md='12'>
                                        <h5 style={{ textAlign: 'center' }}>Interval (Sec)</h5>
                                    </MDBCol>
                                    <MDBCol md='5'>
                                        <MDBInput wrapperClass='mb-4' id='formControlLg1' type='text' size="md"
                                            value={fromInterval} label='From'
                                            onChange={(e) => setFromInterval(e.target.value)} />
                                    </MDBCol>
                                    <MDBCol md='2'></MDBCol>
                                    <MDBCol md='5'>
                                        <MDBInput wrapperClass='mb-4' id='formControlLg2' type='text' size="md"
                                            value={toInterval} label='To'
                                            onChange={(e) => setToInterval(e.target.value)} />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className='g-0'>
                                    <MDBCol md='6'>
                                        <h5 style={{ textAlign: 'center' }}>Pause After</h5>
                                    </MDBCol>
                                    <MDBCol md='1'></MDBCol>
                                    <MDBCol md='5'>
                                        <MDBInput wrapperClass='mb-4' id='formControlLg3' type='text' size="md"
                                            value={stopAfter} label='Contacts No.'
                                            onChange={(e) => setStopAfter(e.target.value)} />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className='g-0'>
                                    <MDBCol md='6'>
                                        <h5 style={{ textAlign: 'center' }}>Start From</h5>
                                    </MDBCol>
                                    <MDBCol md='1'></MDBCol>
                                    <MDBCol md='5'>
                                        <MDBInput wrapperClass='mb-4' id='formControlLg3' type='text' size="md"
                                            value={startFrom}
                                            onChange={(e) => setStartFrom(e.target.value)} />
                                    </MDBCol>
                                </MDBRow>
                                {false && (<MDBRow className='g-0'>
                                    <MDBCol md='4'>
                                        Image File
                                    </MDBCol>
                                    <MDBCol md='8'>
                                        <MDBFile
                                            id="imageInput"
                                            accept=".jpg,.jpeg,.png"
                                            onChange={handleImageChange}
                                        /><br></br>
                                        {selectedImage && (
                                            <img src={selectedImage} alt="Selected Image" style={{ width: 200, height: 200 }} />
                                        )}
                                    </MDBCol>
                                </MDBRow>)}
                            </MDBCardBody>
                        </MDBCol>
                        <MDBCol md='5'>
                            <h5>Enter Message</h5>
                            <MDBTextArea outline="true" rows={10} style={{ marginBottom: '10px', marginRight: '20px' }} value={mainMessage}
                                onChange={(e) => setMainMessage(e.target.value)} />
                            <button className="mb-4 px-5 float-start" style={{ backgroundColor: 'green', color: 'white', border: '0px' }} size='sm' type='button' onClick={initializeMessaging}>Start</button>
                            <button className="mb-4 px-5 mx-2 float-start" style={{ backgroundColor: 'black', color: 'white', border: '0px' }} size='sm' type='button' onClick={pauseMessaging}>Pause</button>
                            <button className="mb-4 px-5 mx-2 float-end" style={{ backgroundColor: 'red', color: 'white', border: '0px' }} size='sm' type='button' onClick={stoppermachine}>Stop</button>
                        </MDBCol>
                        <MDBCol md='3'>
                            <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                <label style={{ marginLeft: '10px', fontSize: 'small' }}>{resultLabel}</label>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBCard>
            </MDBContainer>
        </div>
    )
}

export default Mainpage