import { React, useEffect, useState } from 'react';
import socketService from '../socketService';
import QRCode from 'react-qr-code';
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useParams } from 'react-router-dom';
import '../App.css';
//const apiUrl = process.env.REACT_APP_API_URL;
import {
    MDBContainer,
} from 'mdb-react-ui-kit';
let a = 0;
function Qrcodepage() {
    const socket = socketService.getSocket();
    //const { id } = useParams();
    const [qrCode, setQrCode] = useState("");
    const [mainTitle, setMainTitle] = useState("Please Initialize Session");
    const navigate = useNavigate();
    const [idd] = useState(localStorage.getItem('lanahoid'));
    const createSessionForWhatsapp = () => {
        socket.emit("createSession", {
            id: idd
        });
        setMainTitle("Connecting with WhatsApp . . . . . Please wait..");
    };
    useEffect(() => {
        a = a + 1;
        console.log('reader : ' + a);

        socket.emit("connected", " Hello From Client");
        socket.on("qr", (qr) => {
            setQrCode(qr.qr);
            setMainTitle("Scan QR To Authenticate Whatsapp");
        });
        socket.on('ready', (data) => {
            navigate('/main');

        })
        socket.on("loading_screen_view", (data) => {
            setMainTitle('Loading .... ' + data.percent + '%');
        });
        socket.on("auth_failed", (data) => {
            setMainTitle('Authentication Failed');
            alert('Please sign out user from your main phone WhatsApp and try again please.');
            navigate('/');
        });
        if (a === 1) {
            createSessionForWhatsapp();
        }
    }, []);
    return (
        <div className="App">
            <MDBContainer className="my-5">
                <h1>{mainTitle}</h1>
                <div style={{
                    marginBottom: "40px"
                }}>
                </div>
                <QRCode value={qrCode} ></QRCode>
            </MDBContainer>
        </div>
    )
}

export default Qrcodepage
