import io from 'socket.io-client';
const apiUrl = 'https://wp.ruftanaa.com' //process.env.REACT_APP_API_URL;
let socket;

const socketService = {
    getSocket: () => {
        const savedSocketId = localStorage.getItem('socketId');

        if (!socket || (socket.disconnected && savedSocketId)) {
            const query = savedSocketId ? `socketId=${savedSocketId}` : null;
            socket = io.connect(apiUrl, { query });

            // Handle socket events or anything else needed
            socket.on('connect', () => {
                console.log('Socket connected!');
                if (!savedSocketId) {
                    localStorage.setItem('socketId', socket.id);
                }
            });
            // Handling connection errors
            socket.on('connect_error', (error) => {
                console.error('Connection error:', error);
                // Handle the error as needed, such as showing an alert or performing a specific action
            });
            // Clean-up on page unload
            window.addEventListener('beforeunload', () => {
                localStorage.setItem('socketId', socket.id);
            });
        }

        return socket;
    },
};

export default socketService;